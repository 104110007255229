/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useMemo, useState, useEffect } from 'react'
import { useFrame } from '@react-three/fiber'
import { useGLTF, Float, Outlines, Line, Box, Html, useVideoTexture,RenderTexture, Text, PerspectiveCamera, useAnimations, Edges, Billboard} from '@react-three/drei'
import * as THREE from "three"
import { useSpring, animated, config} from '@react-spring/three'

export function RobotTVContact({portalState, animatedPosition, nodes, animations,contactState ,path,...props}) {
  const group = useRef()
  const { actions } = useAnimations(animations, group)
  const palesRef = useRef()
  const texture = useVideoTexture(path)
  //const texture = useVideoTexture("./textures/HERLPERVALISES.mp4")
  texture.flipY = false
  const degToRad = (degrees) => degrees * (Math.PI / 180)
  const TVmaterial = new THREE.MeshStandardMaterial({ color: "#ec5f3d" })


  useEffect(() =>
    {
      actions["action2"].timeScale = 1.7
      actions["action2"].setLoop(THREE.LoopOnce, 1)
      actions["action2"].clampWhenFinished = true
      actions["action2"].reset().fadeIn(0.5).play()
      
      return () => {
        actions["action2"].fadeOut(0.5)
    }
      
    }, [contactState])
  
  
  
  

  return (
    <Float
        speed={2.5} 
        rotationIntensity={0.1} 
        floatIntensity={1} 
        floatingRange={[1, 1.2]}
    > 

    <group ref={group} {...props} dispose={null}  >
      <group name="Scene">
       
        
        <group name="Armature2" position={[0, -0.958, 0]} scale={0.61}>
          <group name="Bone_1">
            
            <mesh
              name="ecran"
              geometry={nodes.ecran.geometry}
              material={nodes.ecran.material}
              position={[0, 1.57, 0]}
              scale={1.639}
            >
                <meshStandardMaterial map={texture} toneMapped={false} />
            </mesh>
            
            <mesh
              name="tele"
              geometry={nodes.tele.geometry}
              material={TVmaterial}
              position={[0, 1.57, 0]}
              scale={1.639}
            >
               <Outlines thickness={2.9} color="black"  />
            </mesh>
            <mesh
              onClick={() => {
                contactState ? window.open("https://www.linkedin.com/in/baptiste-hoffmann-aa6500133", "_blank") : ""
              }}
              position={[0, -1.8, -0.8]}
              visible={false}
            >
              <boxGeometry args={[1.4, 0.3, 0.4]} />
              
            </mesh> 
            <mesh
              onClick={() => {
                contactState ? window.location.href = "mailto:hoffmanbbaptiste@gmail.com" : ""
              }}
              position={[0, -2.3, -0.8]}
              visible={false}
            >
              <boxGeometry args={[1.4, 0.3, 0.4]} />
            </mesh>         
          </group>
        </group>
      </group>
    </group>

    </Float>
    
  )
}
