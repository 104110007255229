/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useMemo, useState, useEffect } from 'react'
import { useFrame } from '@react-three/fiber'
import { useGLTF, Float, Outlines, Line, Box, Html, useVideoTexture,RenderTexture, Text, PerspectiveCamera, useAnimations, Edges, Trail} from '@react-three/drei'
import * as THREE from "three"
import { useSpring, animated, config} from '@react-spring/three'

export function RobotTV({portalState,visible,animatedPosition, startExperience, contactState,setContactState,...props}) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('./models/robotfinal3.glb')
  
  const { actions } = useAnimations(animations, group)
  const palesRef = useRef()
  const texture = useVideoTexture("./textures/FIRSTVIDEOTV.mp4")
  const textureContact = useVideoTexture("./textures/CONTACT.mp4")
  const textureNext = useVideoTexture("./textures/CLICKONNEXT.mp4")
  texture.flipY = false
  textureContact.flipY = false
  textureNext.flipY = false
  const degToRad = (degrees) => degrees * (Math.PI / 180)
  const TVmaterial = new THREE.MeshStandardMaterial({ color: "#ec5f3d" })
  const TVmaterialblack = new THREE.MeshStandardMaterial({ color: "#FFC300"})
  const TVmaterialmarron = new THREE.MeshStandardMaterial({ color: "#900C3F"})
  const glossy = new THREE.MeshStandardMaterial({ color: "#4e3838", roughness: 0.6, metalness: 0.8})
  const [textureContactState, setTextureContactState] = useState(textureNext)
  const [introAnim, setIntroAnim] = useState("action3")
  const [nameAnimation, setNameAnimation] = useState(null)


  const lunchAnimation = (nameAnimation) => {
        actions[nameAnimation].timeScale = 1.2
        actions[nameAnimation].setLoop(THREE.LoopOnce, 1)
        actions[nameAnimation].clampWhenFinished = true
        actions[nameAnimation].reset().fadeIn(0.5).play()
        
        return () => {
          actions[nameAnimation].fadeOut(0.5)
      }
  }

  useEffect(() =>
    {
      actions[introAnim].timeScale = 1.2
        actions[introAnim].setLoop(THREE.LoopOnce, 1)
        actions[introAnim].clampWhenFinished = true
        actions[introAnim].reset().fadeIn(0.5).play()
        
        return () => {
          actions[introAnim].fadeOut(0.5)
      }
      
      
    }, [introAnim])

    useEffect(() =>
      {
        actions["Action1"].timeScale = 1.2
          actions["Action1"].setLoop(THREE.LoopOnce, 1)
          actions["Action1"].clampWhenFinished = true
          actions["Action1"].reset().fadeIn(0.5).play()
          
          return () => {
            actions["Action1"].fadeOut(0.5)
        }
        
        
      }, [startExperience, contactState, portalState])



  
  useFrame((state, delta) => {
    palesRef.current.rotation.y +=  18 * delta
    
  })
  

  return (
    <Float
        speed={2.5} 
        rotationIntensity={0.6} 
        floatIntensity={1} 
        floatingRange={[1, 1.2]}
    > 
    
    <animated.group ref={group} {...props} dispose={null} position-y={animatedPosition} scale={0.92} rotation={[0, degToRad(210), 0]} visible={visible} >
    <group name="Scene">
        <mesh name="top" geometry={nodes.top.geometry} material={TVmaterialblack} />
        <mesh ref={palesRef} name="pales" geometry={nodes.pales.geometry} material={TVmaterialmarron} />
        <mesh
          name="Sphere005"
          geometry={nodes.Sphere005.geometry}
          material={TVmaterial}
          >
          <Outlines thickness={2} color="black"  />
        </mesh>
        <group name="Armature1">
          <group name="Bone">
            <mesh
              name="Cylinder001"
              geometry={nodes.Cylinder001.geometry}
              material={TVmaterialmarron}
              >
              <Edges linewidth={1.3} scale={1} threshold={15} color="black"/>
            </mesh>
            <mesh name="eye" geometry={nodes.eye.geometry} material={glossy} />
            <mesh
              name="eyecontour"
              geometry={nodes.eyecontour.geometry}
              material={TVmaterialblack}
              >
              <Edges linewidth={1.3} scale={1} threshold={15} color="black"/>
            </mesh>
            <mesh 
            name="Sphere" 
            geometry={nodes.Sphere.geometry} 
            material={TVmaterial} 
            onClick={() =>{
              setNameAnimation("Action1")
              lunchAnimation("Action1")
            }}
            >
                <Edges linewidth={1} scale={1} threshold={15} color="black"/> 
            </mesh>
          </group>
        </group>
        <group name="Armature2">
          <group name="Bone_1" position={[0, -0.568, 0]}>
            <mesh
              name="bottom"
              geometry={nodes.bottom.geometry}
              material={TVmaterialblack}
              position={[0, 0.568, 0]}
              >
              <Edges linewidth={1} scale={1} threshold={15} color="black"/>
            </mesh>
            <mesh
              name="ecran"
              geometry={nodes.ecran.geometry}
              material={nodes.ecran.material}
              position={[0, 0.568, 0]}
              
            >
                <meshStandardMaterial map={texture} toneMapped={false} />
            </mesh>
            <mesh
              name="ecran001"
              geometry={nodes.ecran001.geometry}
              material={nodes.ecran001.material}
              position={[0, 0.568, 0]}
              
            >
                <meshStandardMaterial map={textureNext} toneMapped={false}  />
            </mesh>
            <mesh
              name="joints"
              geometry={nodes.joints.geometry}
              material={TVmaterialmarron}
              position={[0, 0.568, 0]}
            />
            <mesh
            onClick={() =>{
              setIntroAnim(introAnim === "action2" ? "action3" : "action2")
            }}
              name="tele"
              geometry={nodes.tele.geometry}
              material={TVmaterial}
              position={[0, 0.568, 0]}
              >
              <Outlines thickness={1.9} color="black"  />
           </mesh>
            <mesh
              name="tubes"
              geometry={nodes.tubes.geometry}
              material={TVmaterial}
              position={[0, 0.568, 0]}
            />
          </group>
        </group>
      </group>
    </animated.group>
    </Float>
    
  )
}

useGLTF.preload('./models/robotfinal3.glb')