/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useMemo ,useRef, useEffect, useState} from 'react'
import { useGLTF, useTexture, Outlines, PresentationControls, Edges, useAnimations, Text3D, TransformControls, Billboard, ScreenSpace } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import * as THREE from "three"
import { useSpring, animated, config} from '@react-spring/three'
import { RobotTVHelper } from './robotTVhelper'

export function Mallettes({portalState, animatedPosition2,visible,isVisible,textMain ,setIsVisible,HemisphereLightValises, DirectionalLightValises, DirectionalLightPlanet, helpState, positionHelper, ...props}) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('./models/MALETTES2.glb')

  const { actions } = useAnimations(animations, group)
  const thickness_out = 2.5
  const meshtest = useRef()
  const [openVDI, setOpenVDI] = useState("CloseVDI") // Done
  const [openNSX, setOpenNSX] = useState("CloseNSX")
  const [openIntune, setOpenIntune] = useState("CloseIntne") // Done
  const [openAzure, setOpenAzure] = useState("CloseAzure") // Done
  const [openDocker, setOpenDocker] = useState("CloseDocker") // Done
  const [openKQL, setOpenKQL] = useState("CloseKQL") // Done
  const [openNutanix, setOpenNutanix] = useState("CloseNutanix") // Done
  const [openTHREEJS, setOpenTHREEJS] = useState("CloseTHREEJS") // Done
  const [openVMware, setOpenVMware] = useState("CloseVMware") // Done
  const [openAnsible, setOpenAnsible] = useState("CloseAnsible") // Done
  const MallettesMaterial = new THREE.MeshStandardMaterial({ color: "#e9994e", metalness:0.2, roughness:0.8 })
  const MallettesMaterialRouge = new THREE.MeshStandardMaterial({ color: "#d04f2f", metalness:0.2, roughness:3.2 })
  const TextMaterial = new THREE.MeshBasicMaterial({ color: "#353228" })
  const MaterialBlack = new THREE.MeshStandardMaterial({color : "#3c2417"})
  const degToRad = (degrees) => degrees * (Math.PI / 180)

  useEffect(() =>
    {
        actions[openVDI].timeScale = 3.0
        actions[openVDI].setLoop(THREE.LoopOnce, 1)
        actions[openVDI].clampWhenFinished = true
        actions[openVDI].reset().fadeIn(0.5).play()
        
        return () => {
          actions[openVDI].fadeOut(0.5)
      }
      
    }, [openVDI])

    useEffect(() =>
      {
          actions[openNSX].timeScale = 3.0
          actions[openNSX].setLoop(THREE.LoopOnce, 1)
          actions[openNSX].clampWhenFinished = true
          actions[openNSX].reset().fadeIn(0.5).play()
          
          return () => {
            actions[openNSX].fadeOut(0.5)
        }
      
        
      }, [openNSX])

      useEffect(() =>
        {
            actions[openAnsible].timeScale = 3.0
            actions[openAnsible].setLoop(THREE.LoopOnce, 1)
            actions[openAnsible].clampWhenFinished = true
            actions[openAnsible].reset().fadeIn(0.5).play()
            
            return () => {
              actions[openAnsible].fadeOut(0.5)
          }
        
          
        }, [openAnsible])

    useEffect(() =>
      {
          actions[openIntune].timeScale = 3.0
          actions[openIntune].setLoop(THREE.LoopOnce, 1)
          actions[openIntune].clampWhenFinished = true
          actions[openIntune].reset().fadeIn(0.5).play()
          
          return () => {
            actions[openIntune].fadeOut(0.5)
        }
      
        
      }, [openIntune])

      useEffect(() =>
        {
            actions[openAzure].timeScale = 3.0
            actions[openAzure].setLoop(THREE.LoopOnce, 1)
            actions[openAzure].clampWhenFinished = true
            actions[openAzure].reset().fadeIn(0.5).play()
            
            return () => {
              actions[openAzure].fadeOut(0.5)
          }
        
          
        }, [openAzure])

        useEffect(() =>
          {
              actions[openDocker].timeScale = 3.0
              actions[openDocker].setLoop(THREE.LoopOnce, 1)
              actions[openDocker].clampWhenFinished = true
              actions[openDocker].reset().fadeIn(0.5).play()
              
              return () => {
                actions[openDocker].fadeOut(0.5)
            }
          
            
          }, [openDocker])

          useEffect(() =>
            {
                actions[openKQL].timeScale = 3.0
                actions[openKQL].setLoop(THREE.LoopOnce, 1)
                actions[openKQL].clampWhenFinished = true
                actions[openKQL].reset().fadeIn(0.5).play()
                
                return () => {
                  actions[openKQL].fadeOut(0.5)
              }
            
              
            }, [openKQL])

            useEffect(() =>
              {
                  actions[openNutanix].timeScale = 3.0
                  actions[openNutanix].setLoop(THREE.LoopOnce, 1)
                  actions[openNutanix].clampWhenFinished = true
                  actions[openNutanix].reset().fadeIn(0.5).play()
                  
                  return () => {
                    actions[openNutanix].fadeOut(0.5)
                }
              
                
              }, [openNutanix])

              useEffect(() =>
                {
                    actions[openTHREEJS].timeScale = 3.0
                    actions[openTHREEJS].setLoop(THREE.LoopOnce, 1)
                    actions[openTHREEJS].clampWhenFinished = true
                    actions[openTHREEJS].reset().fadeIn(0.5).play()
                    
                    return () => {
                      actions[openTHREEJS].fadeOut(0.5)
                  }
                
                  
                }, [openTHREEJS])

                useEffect(() =>
                  {
                      actions[openVMware].timeScale = 3.0
                      actions[openVMware].setLoop(THREE.LoopOnce, 1)
                      actions[openVMware].clampWhenFinished = true
                      actions[openVMware].reset().fadeIn(0.5).play()
                      
                      return () => {
                        actions[openVMware].fadeOut(0.5)
                    }
                  
                    
                  }, [openVMware])
              
                

  

      useFrame((state, delta) => {
        group.current.rotation.y += delta * 0.05
      })
  
      return (
        <animated.group {...props} dispose={null} position-y={animatedPosition2} visible={visible} >
        <group ref={group} {...props} dispose={null}>
          <group name="Scene">
            <mesh
              onClick={(e) => {
                e.stopPropagation()
                setOpenNSX(openNSX === "OpenNSX" ? "CloseNSX" : "OpenNSX")
              }}
              name="NSX"
              geometry={nodes.NSX.geometry}
              material={MallettesMaterial}
              position={[-1.513, 4.624, 5.359]}
              rotation={[0.848, -0.961, 1.074]}
              >
              <Outlines thickness={thickness_out} color="black"  />
            </mesh>
            <mesh
              onClick={(e) => {
                e.stopPropagation()
                setOpenVDI(openVDI === "OpenVDI" ? "CloseVDI" : "OpenVDI")
              }}
              name="VDI"
              geometry={nodes.VDI.geometry}
              material={MallettesMaterial}
              position={[4.248, 8.665, 5.563]}
              rotation={[0.552, 0.277, -0.027]}
              >
              <Outlines thickness={thickness_out} color="black"  />
            </mesh>
            <mesh
              onClick={(e) => {
                e.stopPropagation()
                setOpenIntune(openIntune === "OpenIntune" ? "CloseIntne" : "OpenIntune")
              }}
              name="Intune"
              geometry={nodes.Intune.geometry}
              material={MallettesMaterial}
              position={[-5.242, 8.326, 0.182]}
              rotation={[0.777, -0.216, 0.645]}
              >
              <Outlines thickness={thickness_out} color="black"  />
            </mesh>
            <mesh
              onClick={(e) => {
                e.stopPropagation()
                setOpenVMware(openVMware === "OpenVMware" ? "CloseVMware" : "OpenVMware")
              }}
              name="Uag"
              geometry={nodes.Uag.geometry}
              material={MallettesMaterial}
              position={[5.168, 9.129, -6.3]}
              rotation={[2.219, 0.635, -1.93]}
              >
              <Outlines thickness={thickness_out} color="black"  />
            </mesh>
            <mesh
              onClick={(e) => {
                e.stopPropagation()
                setOpenNutanix(openNutanix === "OpenNutanix" ? "CloseNutanix" : "OpenNutanix")
              }}
              name="Nutanix"
              geometry={nodes.Nutanix.geometry}
              material={MallettesMaterial}
              position={[5.757, 2.281, -0.846]}
              rotation={[-2.222, -0.018, 1.284]}
              >
              <Outlines thickness={thickness_out} color="black"  />
            </mesh>
            <mesh
              onClick={(e) => {
                e.stopPropagation()
                setOpenTHREEJS(openTHREEJS === "OpenTHREEJS" ? "CloseTHREEJS" : "OpenTHREEJS")
              }}
              name="Three"
              geometry={nodes.Three.geometry}
              material={MallettesMaterial}
              position={[-2.498, 2.999, -6.45]}
              rotation={[-1.116, 0.299, -3.087]}
              >
              <Outlines thickness={thickness_out} color="black"  />
            </mesh>
            <mesh
              onClick={(e) => {
                e.stopPropagation()
                setOpenDocker(openDocker === "OpenDocker" ? "CloseDocker" : "OpenDocker")
              }}
              name="Docker"
              geometry={nodes.Docker.geometry}
              material={MallettesMaterial}
              position={[2.668, 3.008, 3.847]}
              rotation={[-0.817, 0.009, 0.852]}
              >
              <Outlines thickness={thickness_out} color="black"  />
            </mesh>
            <mesh
              onClick={(e) => {
                e.stopPropagation()
                setOpenAnsible(openAnsible === "OpenAnsible" ? "CloseAnsible" : "OpenAnsible")
              }}
              name="Ansible"
              geometry={nodes.Ansible.geometry}
              material={MallettesMaterial}
              position={[2.033, 10.395, 0.716]}
              rotation={[-1.158, -0.251, 2.284]}
              >
              <Outlines thickness={thickness_out} color="black"  />
            </mesh>
            <mesh
              onClick={(e) => {
                e.stopPropagation()
                setOpenKQL(openKQL === "OpenKQL" ? "CloseKQL" : "OpenKQL")
              }}
              name="Kql"
              geometry={nodes.Kql.geometry}
              material={MallettesMaterial}
              position={[-0.587, 13.199, -2.119]}
              rotation={[-1.884, 0.344, -1.526]}
              >
              <Outlines thickness={thickness_out} color="black"  />
            </mesh>
            <mesh
              onClick={(e) => {
                e.stopPropagation()
                setOpenAzure(openAzure === "OpenAzure" ? "CloseAzure" : "OpenAzure")
              }}
              name="Azure"
              geometry={nodes.Azure.geometry}
              material={MallettesMaterial}
              position={[-4.538, 3.752, -0.907]}
              rotation={[2.104, -0.67, 2.576]}
              >
              <Outlines thickness={thickness_out} color="black"  />
            </mesh>
            
            <mesh
              name="Text002"
              geometry={nodes.Text002.geometry}
              material={MallettesMaterialRouge}
              position={[1.856, 17.408, 17.173]}
              rotation={[0.549, 0.277, -0.026]}
              scale={0.773}
              >
              <Outlines thickness={0.9} color="black" angle={0}  />
            </mesh>
            <group
          name="poigne"
          position={[-7.017, 6.3, 7.011]}
          rotation={[-1.043, 0.668, 0.569]}
          scale={0.654}>
          <mesh
            name="BezierCurve002"
            castShadow
            receiveShadow
            geometry={nodes.BezierCurve002.geometry}
            material={MaterialBlack}
          />
          <mesh
            name="BezierCurve002_1"
            geometry={nodes.BezierCurve002_1.geometry}
            material={MaterialBlack}
          />
        </group>
        <mesh
          onClick={(e) => {
            e.stopPropagation()
            setOpenAzure(openAzure === "OpenAzure" ? "CloseAzure" : "OpenAzure")
          }}
          name="BaseAzure"
          geometry={nodes.BaseAzure.geometry}
          material={MallettesMaterial}
          position={[-4.731, -3.125, -2.119]}
          rotation={[2.099, -0.668, 2.573]}
          >
          <Outlines thickness={thickness_out} color="black"  />
        </mesh>
        <mesh
          onClick={(e) => {
            e.stopPropagation()
            setOpenNSX(openNSX === "OpenNSX" ? "CloseNSX" : "OpenNSX")
          }}
          name="BaseNSX"
          geometry={nodes.BaseNSX.geometry}
          material={MallettesMaterial}
          position={[-4.731, -3.125, -2.119]}
          rotation={[2.099, -0.668, 2.573]}
          >
          <Outlines thickness={thickness_out} color="black"  />
        </mesh>
        <mesh
        onClick={(e) => {
          e.stopPropagation()
          setOpenVDI(openVDI === "OpenVDI" ? "CloseVDI" : "OpenVDI")
        }}
          name="BaseVDI"
          geometry={nodes.BaseVDI.geometry}
          material={MallettesMaterial}
          position={[-4.731, -3.125, -2.119]}
          rotation={[2.099, -0.668, 2.573]}
          >
          <Outlines thickness={thickness_out} color="black"  />
        </mesh>
        <mesh
        onClick={(e) => {
          e.stopPropagation()
          setOpenIntune(openIntune === "OpenIntune" ? "CloseIntne" : "OpenIntune")
        }}
          name="BaseIntune"
          geometry={nodes.BaseIntune.geometry}
          material={MallettesMaterial}
          position={[-4.731, -3.125, -2.119]}
          rotation={[2.099, -0.668, 2.573]}
          >
          <Outlines thickness={thickness_out} color="black"  />
        </mesh>
        <mesh
        onClick={(e) => {
          e.stopPropagation()
          setOpenVMware(openVMware === "OpenVMware" ? "CloseVMware" : "OpenVMware")
        }}
          name="BaseUAG"
          geometry={nodes.BaseUAG.geometry}
          material={MallettesMaterial}
          position={[-4.731, -3.125, -2.119]}
          rotation={[2.099, -0.668, 2.573]}
          >
          <Outlines thickness={thickness_out} color="black"  />
        </mesh>
        <mesh
        onClick={(e) => {
          e.stopPropagation()
          setOpenNutanix(openNutanix === "OpenNutanix" ? "CloseNutanix" : "OpenNutanix")
        }}
          name="BaseNutanix"
          geometry={nodes.BaseNutanix.geometry}
          material={MallettesMaterial}
          position={[-4.731, -3.125, -2.119]}
          rotation={[2.099, -0.668, 2.573]}
          >
          <Outlines thickness={thickness_out} color="black"  />
        </mesh>
        <mesh
        onClick={(e) => {
          e.stopPropagation()
          setOpenTHREEJS(openTHREEJS === "OpenTHREEJS" ? "CloseTHREEJS" : "OpenTHREEJS")
        }}
          name="BaseTHREE"
          geometry={nodes.BaseTHREE.geometry}
          material={MallettesMaterial}
          position={[-4.731, -3.125, -2.119]}
          rotation={[2.099, -0.668, 2.573]}
          >
          <Outlines thickness={thickness_out} color="black"  />
        </mesh>
        <mesh
        onClick={(e) => {
          e.stopPropagation()
          setOpenDocker(openDocker === "OpenDocker" ? "CloseDocker" : "OpenDocker")
        }}
          name="BaseDocker"
          geometry={nodes.BaseDocker.geometry}
          material={MallettesMaterial}
          position={[-4.731, -3.125, -2.119]}
          rotation={[2.099, -0.668, 2.573]}
          >
          <Outlines thickness={thickness_out} color="black"  />
        </mesh>
        <mesh
        onClick={(e) => {
          e.stopPropagation()
          setOpenAnsible(openAnsible === "OpenAnsible" ? "CloseAnsible" : "OpenAnsible")
        }}
          name="BaseAnsible"
          geometry={nodes.BaseAnsible.geometry}
          material={MallettesMaterial}
          position={[-4.731, -3.125, -2.119]}
          rotation={[2.099, -0.668, 2.573]}
          >
          <Outlines thickness={thickness_out} color="black"  />
        </mesh>
        <mesh
        onClick={(e) => {
          e.stopPropagation()
          setOpenKQL(openKQL === "OpenKQL" ? "CloseKQL" : "OpenKQL")
        }}
          name="BaseKQL"
          geometry={nodes.BaseKQL.geometry}
          material={MallettesMaterial}
          position={[-4.731, -3.125, -2.119]}
          rotation={[2.099, -0.668, 2.573]}
          >
          <Outlines thickness={thickness_out} color="black"  />
        </mesh>

          </group>
        </group>
        </animated.group>
      )
}

useGLTF.preload('./models/MALETTES2.glb')
