import {CameraControls ,Environment, useVideoTexture ,Stars ,useTexture, Billboard, useGLTF} from "@react-three/drei"
import { Perf } from 'r3f-perf'
import { useThree } from "@react-three/fiber"
import { useEffect, useRef, useState, useMemo, Suspense } from "react"
import * as THREE from "three"
import { RobotTV } from "./robotTV"
import { Mallettes } from "./Mallettes"
import { Mallettes2 } from "./Mallettes2"
import { useSpring, animated, config} from '@react-spring/three'
import { RobotTVHelper } from "./robotTVhelper"
import { RobotTVInfo } from "./robotTVInfo"
import { RobotTVContact } from "./robotTVContact"

export const Experience = ({isMobile, setIsMobile, portalState ,clickedbyHam,setCLickedByHam,contactState ,setContactState,setPortalState, startExperience, helpState, setHelpState,...props}) => {

  //LOAD MODELS
  const { nodes, materials, animations } = useGLTF('./models/robotfinal.glb')
  //VIDEO TEXTURES
  const textureVideoInfoSkills = useVideoTexture("./textures/SKILLS.mp4")
  textureVideoInfoSkills.flipY = false
  const textureVideoInfoExperience = useVideoTexture("./textures/EXPERIENCES.mp4")
  textureVideoInfoExperience.flipY = false
  const textureVideoHelperValises = useVideoTexture("./textures/HERLPERVALISES.mp4")
  textureVideoHelperValises.flipY = false
  const textureVideoHelperComputer = useVideoTexture("./textures/HELPERCOMPUTER.mp4")
  textureVideoHelperComputer.flipY = false
  


  const [isVisible, setIsVisible] = useState(0)
  const [helpVisible, setHelpVisible] = useState(false)
  const [contactVisible, setContactVisible] = useState(false)

  
  const HemisphereLightValises = useRef()
  const DirectionalLightValises = useRef()
  const DirectionalLightPlanet = useRef()
  const textMain = useRef()
  
  const [active, setActive] = useState(0)

  const controlsRef = useRef();
  const scene = useThree((state) => state.scene);
  const [positionHelper, setPositionHelper] = useState([0, -10, 0])
  const [visibleStateMallette, setVisibleStateMallette] = useState(false)
  const [visibleStateMallette2, setVisibleStateMallette2] = useState(false)
  const [visibleStateRobot, setVisibleStateRobot] = useState(true)
  const { size } = useThree()
  
  

useEffect(() => {

    if (portalState === 1) {
      const [zPosition] = isMobile ? [8] : [6]; 
      controlsRef.current.setLookAt(0, 0, zPosition, 0, 0, 0, true)
    } 
    else if (portalState === 2) {

      const [zPosition] = isMobile ? [8.5] : [6.4]; 
      controlsRef.current.setLookAt(1.5, -2.0, zPosition, 0, -0.3, 0, true)
    }
    else if (startExperience === false) {
      const [x, y, z] = isMobile ? [3.2, 2.3, 8] : [4.2, 2.3, 7]
      controlsRef.current.setLookAt(x, y, z, 0, 1, 0, true)
    }

    else {

        const [x, y, z] = isMobile ? [4.0, -2.3, 7] : [3.2, -2.0, 6]
        controlsRef.current.setLookAt(x, y, z, 0, 0, 0, true)
    }
  }, [portalState, isMobile, active, scene, size.width, contactState])

  useEffect(() => {
    if (helpState == true) {
      controlsRef.current.minPolarAngle = Math.PI / 2 - 0.05
      controlsRef.current.maxPolarAngle = Math.PI / 2 + 0.05
      controlsRef.current.minAzimuthAngle = -0.05
      controlsRef.current.maxAzimuthAngle = 0.05
      setHelpVisible(true)
      const [zPositioHelp] = isMobile ? [5] : [4];
      controlsRef.current.setLookAt(0, -7, zPositioHelp, 0, -7, -1, true)
    }
    else if (helpState == false) {
      controlsRef.current.minPolarAngle = 0
        controlsRef.current.maxPolarAngle = Math.PI
        controlsRef.current.minAzimuthAngle = -Infinity
        controlsRef.current.maxAzimuthAngle = Infinity 
      switch (portalState) {
        case 2:
          
        const [zPosition1] = isMobile ? [8.5] : [6.4]; // Use ternary for z-position
        controlsRef.current.setLookAt(1.5, -2.0, zPosition1, 0, -0.3, 0, true)
     
          break
        case 1:
 
          const [zPosition] = isMobile ? [9] : [6]
          controlsRef.current.setLookAt(0, 0, zPosition, 0, 0, 0, true)
          break
      
        default:
          break
      }

     
      setHelpVisible
      const timeout = setTimeout(() => {
        setHelpVisible(false)
      }, (clickedbyHam === true ? 0 : 400))
      setCLickedByHam(false)
  
      return () => clearTimeout(timeout)
    }
    else {

      controlsRef.current.minPolarAngle = 0          
        controlsRef.current.maxPolarAngle = Math.PI     
        controlsRef.current.minAzimuthAngle = -Infinity
        controlsRef.current.maxAzimuthAngle = Infinity

     const [x, y, z] = isMobile ? [3.2, -2.3, 6] : [0.2, 0.3, 4]

     controlsRef.current.setLookAt(x, y, z, 0, 0, 0, true)
     
      }
  }, [helpState, size.width])


  useEffect(() => {


    if (contactState == true && startExperience == true) {
      setContactVisible(true)
      controlsRef.current.minPolarAngle = Math.PI / 2 - 0.05
      controlsRef.current.maxPolarAngle = Math.PI / 2 + 0.05
      
      controlsRef.current.minAzimuthAngle = -0.05
      controlsRef.current.maxAzimuthAngle = 0.05
      const [zPositioHelp] = isMobile ? [5] : [4];
      controlsRef.current.setLookAt(0, -7, zPositioHelp, 0, -7, -1, true)
    }
    else if (contactState == false && startExperience == true && portalState == 0) {
      controlsRef.current.minPolarAngle = 0
        controlsRef.current.maxPolarAngle = Math.PI 
        controlsRef.current.minAzimuthAngle = -Infinity
        controlsRef.current.maxAzimuthAngle = Infinity
      
      const [x, y, z] = isMobile ? [4.0, -2.3, 7] : [3.2, -2.0, 6]

        // Set the camera position after limiting polar angles
      controlsRef.current.setLookAt(x, y, z, 0, 0, 0, true)
      const timeout = setTimeout(() => {
        setContactVisible(false)
      }, 400)
  
      return () => clearTimeout(timeout)

      
    }

    else {
      setContactVisible(false)
      controlsRef.current.minPolarAngle = 0
      controlsRef.current.maxPolarAngle = Math.PI 
      controlsRef.current.minAzimuthAngle = -Infinity
      controlsRef.current.maxAzimuthAngle = Infinity
    }
  }, [contactState, portalState])

  useEffect(() => {
    const zoomout = isMobile ? 10 : 7
    const zoomin = isMobile ? 7 : 5
    controlsRef.current.maxDistance = zoomout
    controlsRef.current.minDistance = zoomin
  },[isMobile])
  
  const experience2 = startExperience

  const degToRad = (degrees) => degrees * (Math.PI / 180);

  const { animatedPosition, animatedPosition2, animatedPosition3 } = useSpring({
    from: { animatedPosition: 0, animatedPosition2: 6, animatedPosition3 : 6 },
    to: async (next) => {
      if (portalState === 1) { // Run animation Valises
        await next({ animatedPosition3: 6})
        await next( setVisibleStateMallette2(false))
        await next({ animatedPosition: 6 })
        await next( setVisibleStateRobot(false))
        await next( setVisibleStateMallette(true))
        await next({ animatedPosition2: -1.5})
        
      }
      else if (portalState === 0) { // Run animation Planets
        await next({ animatedPosition2: 6 })
        await next( setVisibleStateMallette(false))
        await next({ animatedPosition3: 6})
        await next( setVisibleStateMallette2(false))
        await next( setVisibleStateRobot(true))
        await next({ animatedPosition: 0})
        
      }
      else if (portalState === 2) { // Run animation Planets
        await next({ animatedPosition2: 6 })
        await next( setVisibleStateMallette(false))
        await next({ animatedPosition: 6})
        await next( setVisibleStateRobot(false))
        await next( setVisibleStateMallette2(true))
        await next({ animatedPosition3: (isMobile ? -0.45 : -0.2)})
      }
      
      
    },
    config: { mass: 1, tension: 130, friction: 9 }
  })

  
  return (
    <>

 
<Stars radius={50} depth={1} count={3000} factor={4} saturation={4} fade speed={0.1} />    
    <color attach="background" args={['#423633']} />
      <ambientLight intensity={1.8} />
      <Environment preset="sunset" />
      <directionalLight position={[-16, 20, 7]} intensity={7.5} color={'orange'}/>
      <RobotTV
        visible={visibleStateRobot}
        portalState = {portalState}
        animatedPosition = {animatedPosition}
        contactState = {contactState}
        setContactState = {setContactState}
        startExperience={startExperience}
      />

        <Mallettes 
          scale={0.5} 
          visible={visibleStateMallette} 
          isVisible={isVisible} 
          setIsVisible={setIsVisible}
          HemisphereLightValises={HemisphereLightValises}
          DirectionalLightValises={DirectionalLightValises}
          DirectionalLightPlanet={DirectionalLightPlanet}
          textMain={textMain}
          portalState = {portalState}
          animatedPosition2 = {animatedPosition2}
          helpState = {helpState}
          positionHelper = {positionHelper}          
        />
        <Mallettes2
          scale={0.80} 
          visible={visibleStateMallette2} 
          isVisible={isVisible} 
          setIsVisible={setIsVisible}
          HemisphereLightValises={HemisphereLightValises}
          DirectionalLightValises={DirectionalLightValises}
          DirectionalLightPlanet={DirectionalLightPlanet}
          textMain={textMain}
          animatedPosition3 = {animatedPosition3}
          portalState = {portalState}
        ></Mallettes2>       
        <RobotTVContact
          visible={contactVisible}
          scale={1}
          position={[0, -5.8, 0]}
          rotation={[0, degToRad(180), 0]}
          path={"./textures/CONTACT.mp4"} 
          contactState = {contactState}
          nodes = {nodes}
          animations = {animations}
        /> 
        <RobotTVHelper
          visible={helpVisible}
          scale={1}
          position={[0, -5.8, 0]}
          rotation={[0, degToRad(180), 0]}
          path={portalState == 1 ? "./textures/HERLPERVALISES.mp4" : "./textures/HELPERCOMPUTER.mp4"} 
          startExperience = {experience2}
          textureVideoHelperComputer = {textureVideoHelperComputer}
          textureVideoHelperValises = {textureVideoHelperValises}
          portalState={portalState}
          nodes = {nodes}
          animations = {animations}
        /> 
        <Billboard

        >
        <RobotTVInfo
          size = {size}
          isMobile = {isMobile}
          portalState={portalState}
          visible={portalState == 0 ? false : true}
          scale={1.5}
          path={ portalState == 1 ? "./textures/SKILLS.mp4" : "./textures/EXPERIENCES.mp4"}  
          textureVideoInfoExperience = {textureVideoInfoExperience}
          textureVideoInfoSkills = {textureVideoInfoSkills}
          helpState = {helpState}
          nodes = {nodes}
          animations = {animations}
        />  
        </Billboard>    
      <CameraControls
        ref={controlsRef}
        enabled = {true}
        truckSpeed = {0.0}
        makeDefault
        minDistance={5}
        maxDistance={10}
        minX={0}
        maxX={0} 
        minY={0}
        maxY={0} 
      />
    </>
  )
}
useGLTF.preload('./models/robotfinal.glb')

