import { Hud, OrthographicCamera } from "@react-three/drei";
import { useFrame } from "@react-three/fiber"
import { useMemo, useRef, useState, useEffect } from "react"
import { Color,  } from "three"
import { useSpring} from '@react-spring/three'

import portalVertexShader from './shaders/portal/vertex.glsl'
import portalFragmentShader from './shaders/portal/fragment.glsl'



export const UITransition = ({ isMobile, setIsMobile,startExperience, setStartExperience, ...props }) => {
  const { Intensity } = useSpring({
    from: { Intensity: isMobile === true ? 0.36 : 0.22 },
    
    to: async (next) => {
      if (startExperience == true) {
        
     
        await next({ Intensity: 2})
      }
    },
    config: { 
      mass: 2,         // Heavier mass
      tension: 2,    // Faster return
      friction: 7,    // Less friction, more bounce
      precision: 0.01  // Stopping precision
    }
  })
  const [lerpFactor, setLerpFactor] = useState(0)
  const mesh = useRef()
  const hover = useRef(false)
  const portalMaterial = useRef()

  const uniforms = useMemo(
    () => ({
      uTime: {
        value: 0.0,
      },
      u_colorA: { value: new Color("#f9d690") },
      u_colorB: { value: new Color("#FEB3D9") },
      uIntensity: {value: 0},
      uResolution: { value: [window.innerWidth, window.innerHeight] }
    }),
    []
  )

  useEffect(() => {
    const handleResize = () => {
      uniforms.uResolution.value = [window.innerWidth, window.innerHeight];
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [uniforms]);
  
  

  useFrame((state, delta) => {
    const { clock } = state;
    mesh.current.material.uniforms.uTime.value = 0.4 * clock.getElapsedTime()
    mesh.current.material.uniforms.uIntensity.value = Intensity.get()
      
  })


    return (
        <Hud>
        <OrthographicCamera
          makeDefault
          top={1}
          right={1}
          bottom={-1}
          left={-1}
          near={0}
          far={1}
        />

    
      <mesh visible={true} ref={mesh} >
      <planeGeometry args={[2, 2, 20, 20]} />
      <shaderMaterial
        ref={portalMaterial}
        fragmentShader={portalFragmentShader}
        vertexShader={portalVertexShader}
        uniforms={uniforms}
        transparent={true}
      />
      </mesh>
      </Hud>
    );
  };  