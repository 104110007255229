uniform float uIntensity;     // Controls the size of the circles
uniform vec2 uResolution;     // Resolution (width and height) of the plane
uniform vec3 u_colorA;        // Color of the first circle
uniform vec3 u_colorB;        // Color of the second circle

varying vec2 vUv;             // UV coordinates of the plane
varying float vZ;

vec3 colorA = vec3(1.000, 0.777, 0.052);  // Color of the first circle (transparent)
vec3 colorB = vec3(0.777, 0.000, 0.000);  // Color of the second circle (solid)

vec3 colorC = vec3(0.912,0.191,0.652);
vec3 colorD = vec3(1.000,0.777,0.052);

void main() {
    // Calculate the aspect ratio to maintain a circular shape
    vec2 aspectRatio = vec2(uResolution.x / uResolution.y, 1.0);
    
    // Adjust the UV coordinates to maintain a circular shape
    vec2 uv = (vUv - 0.5) * aspectRatio + 0.5;

    // Calculate the distance from the center of the plane (0.5, 0.5)
    float distanceFromCenter = distance(uv, vec2(0.5, 0.5));

    // Find the larger dimension (width or height) of the screen
    float maxDimension = max(uResolution.x, uResolution.y);

    // Circle 1 (first circle) radius
    float radius1 = uIntensity * (maxDimension / uResolution.y) * 0.5;

    // Circle 2 (second circle) radius (slightly bigger)
    float radius2 = uIntensity * (maxDimension / uResolution.y) * 0.54;

    // Alpha for the first circle (soft edge, transparent)
    /* float alpha1 = smoothstep(radius1 - 0.01, radius1 + 0.01, distanceFromCenter); */
    float alpha1 = step(radius1, distanceFromCenter);

    // Alpha for the second circle (hard edge, fully solid)
    float alpha2 = step(radius2, distanceFromCenter);

    // If we're within the second (larger) circle, draw it
    if (distanceFromCenter < radius2 && distanceFromCenter >= radius1) {
        gl_FragColor = vec4(colorB, 1.0); // Solid second circle
    } 
    // Otherwise, draw the first circle with transparency
    else if (distanceFromCenter < radius1) {
        gl_FragColor = vec4(u_colorA, 0.0); // Transparent first circle
    } 
    // Outside both circles, set the background color
    else {
        vec3 colorbackground = mix(colorC, colorA, vUv.x * 0.29 + 0.4);

        gl_FragColor = vec4(colorbackground,1.0);
        
    }
}





