/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useMemo, useState, useEffect } from 'react'
import { useGLTF, Float, Outlines, useVideoTexture, useAnimations} from '@react-three/drei'
import * as THREE from "three"

export function RobotTVInfo({portalState, nodes, animations,helpState,textureVideoInfoExperience,textureVideoInfoSkills, isMobile, size ,animatedPosition, path,...props}) {
  const group = useRef()
  const { actions } = useAnimations(animations, group)
  const materialRef = useRef()
  const degToRad = (degrees) => degrees * (Math.PI / 180)
  const TVmaterial = new THREE.MeshStandardMaterial({ color: "#ec5f3d" })
  const [animationName, setAnimationName] = useState(null)

  const lunchAnimation = (nameAnimation) => {
        actions[nameAnimation].timeScale = 1.7
        actions[nameAnimation].setLoop(THREE.LoopOnce, 1)
        actions[nameAnimation].reset().fadeIn(0.5).play()
        
        return () => {
          actions[nameAnimation].fadeOut(0.5)
      }
  }

  useEffect(() =>
    {
      actions["action2"].timeScale = 1.7
      actions["action2"].setLoop(THREE.LoopOnce, 1)
      actions["action2"].clampWhenFinished = true
      actions["action2"].reset().fadeIn(0.5).play()
      
      return () => {
        actions["action2"].fadeOut(0.5)
    }
      
    }, [portalState, helpState])

  useEffect(() => {
    if (group.current) {
      const [x, y, z] = isMobile ? [0, 6.5, -8] : [6.7, 5.0, -8]
      const [rotationy] = isMobile ? [180] : [130]
      
      // Set the position using the .set() method
      group.current.position.set(x, y, z)
      group.current.rotation.set(0, degToRad(rotationy), 0)
    }
  }, [isMobile, size.width])

  useEffect(() => {
    materialRef.current.map = (portalState === 1 ? textureVideoInfoSkills : textureVideoInfoExperience)
  },[portalState])

  
  
  
  

  return (

    <Float
        speed={2.5} 
        rotationIntensity={0.1} 
        floatIntensity={1.2} 
        floatingRange={[1, 1.2]}
    > 

    <group ref={group} {...props} dispose={null} >
      <group name="Scene">
        
       
        
        <group name="Armature2" position={[0, -0.958, 0]} scale={0.61}>
          <group name="Bone_1">
            
            <mesh
              name="ecran"
              geometry={nodes.ecran.geometry}
              material={nodes.ecran.material}
              position={[0, 1.57, 0]}
              scale={1.639}
              onClick={() =>{
                setAnimationName("action2")
                lunchAnimation("action2")
              }}
            >
              
                <meshStandardMaterial ref={materialRef} toneMapped={false} />
            </mesh>
            
            <mesh
              name="tele"
              geometry={nodes.tele.geometry}
              material={TVmaterial}
              position={[0, 1.57, 0]}
              scale={1.639}
            >
               <Outlines thickness={2.3} color="black"  />
            </mesh>
            
          </group>
        </group>
      </group>
    </group>

    </Float>
    
  )
}
